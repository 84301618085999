import axios, {
  type AxiosInstance,
  type AxiosResponse,
  type AxiosRequestConfig,
} from 'axios';
import { ROUTER_LINK } from '@/shared/types.ts';
import { PUBLIC_ROUTES } from '@/router/index.ts';

export enum HttpCodes {
  'UNAUTHORIZED' = 401,
  'FORBIDDEN' = 403,
  'TOO_MANY_ATTEMPTS' = 429,
  'SUCCESS' = 200,
  'INTERNAL_SERVER_ERROR' = 500,
}

export const BASE_URL = '/api';

class HttpClient {
  private httpAgent: AxiosInstance;

  constructor() {
    this.httpAgent = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });

    this.httpAgent.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          PUBLIC_ROUTES.some((route: string) =>
            window.location.pathname.includes(route),
          ) &&
          window.location.pathname !== ROUTER_LINK.LOGIN_PAGE
        ) {
          return;
        }
        switch (error.response.status) {
          case HttpCodes.UNAUTHORIZED:
            window.location.href = ROUTER_LINK.LOGIN_PAGE;
            break;
          case HttpCodes.INTERNAL_SERVER_ERROR:
            window.location.href = ROUTER_LINK.INTERNAL_SERVER_ERROR_PAGE;
            break;
          case HttpCodes.FORBIDDEN:
            window.location.href = ROUTER_LINK.FORBIDDEN_PAGE;
            break;
          default:
            break;
        }
        return Promise.reject(error);
      },
    );

    // @ts-ignore
    this.httpAgent.interceptors.request.use((config: AxiosRequestConfig) => ({
      ...config,
      headers: {
        ...config.headers,
      },
    }));
  }

  async get<T>(url: string) {
    try {
      const response = await this.httpAgent.get<T>(url);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      }
      throw new Error((error as Error).message ?? 'Что-то пошло не так');
    }
  }

  async post<T>(
    url: string,
    data?: object,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>> {
    try {
      const response = await this.httpAgent.post<T>(url, data, {
        withCredentials: true,
        ...config,
      });

      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        throw error;
      }
      throw new Error((error as Error).message ?? 'Что-то пошло не так');
    }
  }
}

export const httpClient = new HttpClient();
