import { DATA_ACCESS_RESTRICTION_FLAG } from '@/core/services/profile.service';
import { ROUTER_LINK, PERSONAL_AREA_LINK } from '@/shared/types.ts';

const PERSONAL_AREA_ROUTES = [
  {
    path: PERSONAL_AREA_LINK.PROFILE,
    name: 'ProfilePage',
    component: () => import('@/pages/PersonalArea/ProfilePage.vue'),
  },
  {
    path: PERSONAL_AREA_LINK.REALTORS,
    name: 'RealtorsPage',
    component: () => import('@/pages/PersonalArea/RealtorsPage.vue'),
    meta: {
      restrictions: [DATA_ACCESS_RESTRICTION_FLAG?.ADMINISTRATION],
    },
  },
  {
    path: `${PERSONAL_AREA_LINK.REALTORS}/:id`,
    name: 'RealtorPage',
    component: () => import('@/pages/PersonalArea/RealtorPage.vue'),
    meta: {
      restrictions: [DATA_ACCESS_RESTRICTION_FLAG?.ADMINISTRATION],
    },
  },
  {
    path: PERSONAL_AREA_LINK.CLIENTS,
    name: 'ClientsPage',
    component: () => import('@/pages/PersonalArea/ClientsPage.vue'),
  },
  {
    path: `${PERSONAL_AREA_LINK.CLIENTS}/:id`,
    name: 'ClientPage',
    component: () => import('@/pages/PersonalArea/ClientPage.vue'),
  },
  {
    path: `${PERSONAL_AREA_LINK.RESERVATIONS}`,
    name: 'ReservationsPage',
    component: () => import('@/pages/PersonalArea/ReservationsPage.vue'),
  },
  {
    path: `${PERSONAL_AREA_LINK.RESERVATIONS}/:id`,
    name: 'ReservationRequestPage',
    component: () => import('@/pages/PersonalArea/ReservationRequestPage.vue'),
  },
  {
    path: `${PERSONAL_AREA_LINK.DEALS}`,
    name: 'DealsPage',
    component: () => import('@/pages/PersonalArea/DealsPage.vue'),
  },
  {
    path: `${PERSONAL_AREA_LINK.DEALS}/:id`,
    name: 'DealRequestPage',
    component: () => import('@/pages/PersonalArea/DealRequestPage.vue'),
  },
  {
    path: PERSONAL_AREA_LINK.FIXATIONS,
    name: 'FixationsPage',
    component: () => import('@/pages/PersonalArea/FixationsPage.vue'),
  },
  {
    path: PERSONAL_AREA_LINK.DEVELOPERS,
    name: 'DevelopersPage',
    component: () => import('@/pages/PersonalArea/DevelopersPage.vue'),
    meta: {
      restrictions: [DATA_ACCESS_RESTRICTION_FLAG?.ADMINISTRATION],
    },
  },
  {
    path: PERSONAL_AREA_LINK.SELECTIONS,
    name: 'SelectionsPage',
    component: () => import('@/pages/PersonalArea/SelectionsPage.vue'),
  },
];

export const ROUTES = [
  {
    path: ROUTER_LINK.LOGIN_PAGE,
    name: 'LoginPage',
    component: () => import('@/pages/LoginPage.vue'),
  },
  {
    path: ROUTER_LINK.MAIN_PAGE,
    name: 'MainPage',
    component: () => import('@/pages/MainPage.vue'),
  },
  {
    path: ROUTER_LINK.PEREUSTUPKI_PAGE,
    name: 'RealEstateDealPage',
    component: () => import('@/pages/RealEstateDealPage.vue'),
  },
  {
    path: ROUTER_LINK.VTORICHNOE_GILIE_PAGE,
    name: 'SecondaryHousingPage',
    component: () => import('@/pages/SecondaryHousingPage.vue'),
  },
  {
    path: ROUTER_LINK.IPOTEKA_PAGE,
    name: 'IpotekaPage',
    component: () => import('@/pages/IpotekaPage.vue'),
  },
  {
    path: `${ROUTER_LINK.PRESENTATION_OBJECT_PAGE}/:id`,
    name: 'PresentationPage',
    component: () => import('@/pages/PresentationPage.vue'),
  },
  {
    path: `${ROUTER_LINK.PROJECT_PAGE}/:id`,
    name: 'ProjectPage',
    component: () => import('@/pages/ProjectPage.vue'),
  },
  {
    path: ROUTER_LINK.CLIENT_FIXATION_PAGE,
    name: 'ClientFixationPage',
    component: () => import('@/pages/ClientFixationPage.vue'),
  },
  {
    path: ROUTER_LINK.FAVORITES_PAGE,
    name: 'FavoritesPage',
    component: () => import('@/pages/FavoritesPage.vue'),
  },
  {
    path: ROUTER_LINK.COMPARISON_PAGE,
    name: 'ComparisonPage',
    component: () => import('@/pages/ComparisonPage.vue'),
  },
  {
    path: `${ROUTER_LINK.RESERVATION_OBJECT_PAGE}/:id`,
    name: 'ReservationObjectPage',
    component: () => import('@/pages/ReservationObjectPage.vue'),
  },
  {
    path: ROUTER_LINK.TARIFF_MAP_PAGE,
    name: 'TariffMapPage',
    component: () => import('@/pages/TariffMapPage.vue'),
  },
  {
    path: ROUTER_LINK.PERSONAL_AREA,
    name: 'PersonalArea',
    children: PERSONAL_AREA_ROUTES,
    redirect: `${ROUTER_LINK.PERSONAL_AREA}/${PERSONAL_AREA_LINK.PROFILE}`,
  },
  {
    path: `${ROUTER_LINK.PERSONAL_CLIENT}/:id`,
    name: 'PersonalClientPage',
    component: () => import('@/pages/PersonalClientPage.vue'),
  },
  {
    path: ROUTER_LINK.EDUCATION_PAGE,
    name: 'EducationPage',
    component: () => import('@/pages/EducationPage.vue'),
  },
  {
    path: ROUTER_LINK.CONTACTS,
    name: 'ContactsPage',
    component: () => import('@/pages/ContactsPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: () => import('@/pages/NotFoundPage.vue'),
  },
  {
    path: ROUTER_LINK.FORBIDDEN_PAGE,
    name: 'ForbbidenPage',
    component: () => import('@/pages/ForbiddenPage.vue'),
  },
  {
    path: ROUTER_LINK.INTERNAL_SERVER_ERROR_PAGE,
    name: 'InternalServerErrorPage',
    component: () => import('@/pages/InternalServerErrorPage.vue'),
  },
];
