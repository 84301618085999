import type { AxiosResponse } from 'axios';
import { httpClient } from '@/core/services/http-client.service.ts';
import type {
  TChangePasswordData,
  TProfileRealtor,
  TProfileRealtorData,
} from '../types.ts';

export const getRealtorProfileData = async (): Promise<TProfileRealtorData> => {
  try {
    const response = (
      await httpClient.get<TProfileRealtorData>(`/realtor/profile`)
    )?.data;

    return response;
  } catch (error) {
    throw error;
  }
};

export const changePasswordFetch = async (
  data: TChangePasswordData,
): Promise<AxiosResponse> => {
  try {
    const response = await httpClient.post('/auth/update/password', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeAvatarProfileFetch = async (
  data: FormData,
): Promise<AxiosResponse> => {
  try {
    const response = await httpClient.post('/realtor/upload/image', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const changeAvatarAgencyFetch = async (
  data: FormData,
): Promise<AxiosResponse> => {
  try {
    const response = await httpClient.post('/agency/upload/image', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getProfile = async (): Promise<TProfileRealtor> => {
  try {
    const response = (await httpClient.get<TProfileRealtor>(`/realtor/realtor`))
      .data;
    return response;
  } catch (error) {
    return {
      id: 0,
      name: '',
      lastName: '',
      surName: '',
      userId: '',
      workWithClient: true,
      role: '',
      roleName: '',
    };
  }
};

export const postUpdateWorkWithClient = async (
  isWorkWithClient: boolean,
): Promise<AxiosResponse> => {
  try {
    const response = await httpClient.post(
      `/realtor/update/workwithclient?flag=${isWorkWithClient}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};
