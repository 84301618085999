import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';
import { isAxiosError } from 'axios';
import { HttpCodes } from '@/core/services/http-client.service';
import { checkAuthFetch } from '@/modules/login/service/login-page.transport';
import { getProfile } from '@/modules/personalArea/profile/service/profile-page.transport.ts';
import {
  DATA_ACCESS_RESTRICTION_FLAG,
  useProfileService,
} from '@/core/services/profile.service.ts';
import { TOAST_VARIANT, useToastNotify } from '@/hooks/use-toast-notify.ts';
import router, { PUBLIC_ROUTES } from '@/router/index.ts';
import { ROUTER_LINK } from '@/shared/types.ts';

export const initAuthChecking = async () => {
  const isAuth = await checkAuthFetch();
  const { openToast } = useToastNotify();

  const location = window.location.pathname;

  if (
    !isAuth &&
    !PUBLIC_ROUTES.some((route: string) => location.includes(route))
  )
    return router.push(ROUTER_LINK.LOGIN_PAGE);

  const pingCheckAuth = setInterval(async () => {
    try {
      await checkAuthFetch();
    } catch (error: unknown) {
      if (!isAxiosError(error)) return;

      if (error.response?.status === HttpCodes.UNAUTHORIZED) {
        clearInterval(pingCheckAuth);
        openToast('Сессия истекла, войдите снова', TOAST_VARIANT.info);

        return router.push(ROUTER_LINK.LOGIN_PAGE);
      }
    }
  }, 50000);
};

export const tryToEnterPrivateRoute = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const profile = await getProfile();

    if (!profile.id && !PUBLIC_ROUTES.some((item) => to.path.includes(item))) {
      router.push(ROUTER_LINK.LOGIN_PAGE);
    }

    const { initProfileService, checkProfileAccessToData } =
      useProfileService();
    initProfileService(profile);

    const hasRouteRestriction = Array.isArray(to.meta.restrictions);

    if (hasRouteRestriction) {
      const isAllowNext = checkProfileAccessToData(
        to.meta.restrictions as Array<DATA_ACCESS_RESTRICTION_FLAG>,
        profile,
      );

      return isAllowNext ? next() : next({ name: 'NotFoundPage' });
    }

    next();
  } catch (error) {
    return next({ name: 'LoginPage' });
  }
};
