import { createRouter, createWebHistory } from 'vue-router';
import { ROUTER_LINK } from '../shared/types.ts';
import { tryToEnterPrivateRoute } from './guards.ts';
import { ROUTES } from './routes.ts';

const router = createRouter({
  history: createWebHistory(),
  routes: ROUTES,
  scrollBehavior(to, _) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: 80,
            behavior: 'smooth',
          });
        }, 500);
      });
    }
  },
});

export const PUBLIC_ROUTES: Array<string> = [
  ROUTER_LINK.LOGIN_PAGE,
  ROUTER_LINK.NOT_FOUND_PAGE,
  ROUTER_LINK.PERSONAL_CLIENT,
  ROUTER_LINK.PRESENTATION_OBJECT_PAGE,
  ROUTER_LINK.COMPARISON_PAGE,
];

router.beforeEach(async (to, from, next) => {
  if (!PUBLIC_ROUTES.includes(to.path) || to.path.startsWith('/not')) {
    await tryToEnterPrivateRoute(to, from, next);
  } else {
    next();
  }
});

export default router;
