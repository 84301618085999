import { createVfm } from 'vue-final-modal';
import '@/styles/_global.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import { createYmaps } from 'vue-yandex-maps';
import * as Sentry from '@sentry/vue';
import { IconHeart, IconUsers } from '@/assets/icons/index.ts';
import App from './App.vue';
import router from '@/router/index.ts';
import { toastOptions } from './shared/Toast.ts';

const app = createApp(App);
const vfm = createVfm();

/**
 * Инструкция для настройки Sentry для Vue 3
 * https://docs.sentry.io/platforms/javascript/guides/vue/#install
 */
const render = () => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_GLITCHTIP_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  app.component('IconHeart', IconHeart);
  app.component('IconUsers', IconUsers);

  app.use(createPinia());
  app.use(router);
  app.use(
    createYmaps({
      apikey: import.meta.env.VITE_API_KEY_YANDEX_MAP ?? '',
    }),
  );
  app.use(vfm);

  app.use(Toast, toastOptions);

  app.mount('#app');
};

if (
  'serviceWorker' in navigator &&
  /* window.location.protocol === 'https:' */ false
) {
  navigator.serviceWorker
    .register('/sw.js')
    .then((reg) => {
      // если происходит установка СВ
      if (reg.installing) {
        // получаем устанавливаемый СВ
        const sw = reg.installing || reg.waiting;
        // обрабатываем изменения состояния СВ
        sw.onstatechange = () => {
          // рендерим приложение после активации СВ
          if (sw.state === 'activated') {
            render();
          }
        };
      } else {
        // если СВ уже установлен, просто рендерим приложение
        render();
      }
    })
    .catch(() => -1);
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });
} else {
  // если СВ не поддерживается браузером, рендерим приложение
  render();
}
